import Button from "@/components/button"
import React from "react"

export interface ICard {
  title: string
  description?: string
  link: string
  code?: string
  more?: string
  img: string
  tags: string[]
}

export interface CardProps extends ICard {
  index: number
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  index,
  img,
  link,
  more,
  tags,
  code,
}) => {
  return (
    <div className="card">
      <div className="card__background">
        <picture>
          <img src={img} alt="" />
        </picture>
      </div>
      <div className="card__content">
        <div>
          <div className="card__header">
            <h2 className="card__title">{title}</h2>
            <h3 className="card__number">{index < 10 ? "0" + index : index}</h3>
          </div>
          {description && <p className="card__description">{description}</p>}
        </div>
        <div>
          {tags && (
            <div className="tags">
              {tags.map(el => (
                <div className="tags__item">{el}</div>
              ))}
            </div>
          )}
          <div className="card__buttons">
            {more && (
              <Button>
                <a href={more}>more</a>
              </Button>
            )}
            {code && (
              <Button>
                <a href={code}>code</a>
              </Button>
            )}
            <Button>
              <a href={link} target="_blank">
                visit
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
