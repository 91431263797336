import React from "react"

const Button = ({ children }) => {
  return (
    <div className="button">
      <div className="button__content">{children}</div>
      <div className="button__shadow"></div>
    </div>
  )
}

export default Button
