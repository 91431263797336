import ContactForm from "@/components/contact-form"
import Navbar from "@/components/navbar"
import SEO from "@/components/seo"
import React from "react"
import "../styles/scss/global.scss"
import Cards from "@/components/Cards"

const IndexPage = () => {
  return (
    <main className="content">
      <Navbar></Navbar>
      <div className="gutters">
        <h1 className="content__title">Projects</h1>
        <Cards></Cards>
        <SEO title="Home" />
        {/* {links.map((el: any) => (
          <div className="col">
            <h2>{el.title}</h2>
            <div className="row">
              <a href={el.link} className="button">
                check out
              </a>
              {el.github && (
                <a href={el.github} className="button">
                  code
                </a>
              )}
            </div>
          </div>
        ))} */}
      </div>
      {/* <header className="header text--gray">
        <h1>Raz, dwa, trzy</h1>
      </header> */}
      {/* <div className="gutters">
        <Card {...props}></Card>
      </div> */}
      {/* <ContactForm></ContactForm> */}
    </main>
  )
}

export default IndexPage
