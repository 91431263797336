export const TAGS = {
  javascript: 'javascript',
  vue: 'vue.js',
  react: 'react.js',
  node: 'node.js',
  jwt: 'jwt',
  rest: 'rest api',
  express: 'express',
  mongo: 'mongodb',
  mongoose: 'mongoose',
  gatsby:'gatsbyjs',
  gsap: 'gsap',
  webpack: 'webpack',
  css:'css',
  html:'html',
  typescript: 'typescript',
  vuex: 'vuex',
  ws: 'socket.io',
  vueRouter: 'vue router'
}