import React, { ChangeEventHandler } from "react"

const Checkbox: React.FC<{ cb: ChangeEventHandler; text: string }> = ({
  cb,
  text,
}) => {
  return (
    <label className="container">
      {text}
      <input type="checkbox" onChange={cb} />
      <span className="checkmark"></span>
    </label>
  )
}

export default Checkbox
