import React, { useEffect, useState } from "react"
import Card, { ICard } from "@/components/card"
import { cards } from "@/content/cards"
import { TAGS } from "@/constants/tags"
import Checkbox from "./checkbox"

const filters: { [key: string]: any } = Object.values(TAGS).reduce(
  (acc, val: string) => {
    Object.defineProperty(acc, val, {
      value: false,
      writable: true,
      enumerable: true,
    })
    return acc
  },
  {}
)

const Cards: React.FC<{}> = () => {
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [activeCards, setActiveCards] = useState<ICard[]>([])

  useEffect(() => {
    if (!Object.keys(activeFilters).length) {
      setActiveCards(cards)
    } else {
      setActiveCards(
        cards.filter(card =>
          Object.values(activeFilters).every(tag => card.tags.includes(tag))
        )
      )
    }
  }, [activeFilters])

  return (
    <>
      <div className="filters">
        <h2>Filters</h2>
        <div className="filters__content">
          {Object.entries(filters).map(([key], index) => (
            <div className="filter" key={`filter ${index}`}>
              <Checkbox
                text={key}
                cb={(e: React.ChangeEvent<HTMLInputElement>) => {
                  filters[key] = e.target.checked
                  setActiveFilters(
                    Object.entries(filters)
                      .filter(el => el[1] == true)
                      .map(el => el[0])
                  )
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="cards">
        {activeCards.map((el, index: number) => (
          <Card
            key={`card ${index.toString()}`}
            {...{ ...el, index: index + 1 }}
          ></Card>
        ))}
      </div>
    </>
  )
}

export default Cards
