import { ICard } from "@/components/card"
import { TAGS } from "@/constants/tags"

export const cards: ICard[] = [
  {
    title: "Todo+",
    description: `Trello inspired fullstack app where users can create personal "todo
    list" boards, collaborate using chat, manage projects with multiple
    users. Click 'more' to take a look at the features or visit it yourself.`,
    link: "https://td-plus.herokuapp.com/#/",
    code: "https://github.com/mwatford/td_plus",
    more: "/todo-plus",
    img: "todo.webp",
    tags: [
      TAGS.vue,
      TAGS.vuex,
      TAGS.vueRouter,
      TAGS.node,
      TAGS.express,
      TAGS.jwt,
      TAGS.mongo,
      TAGS.mongoose,
      TAGS.ws,
      TAGS.rest,
    ],
  },
  {
    title: "Nowa Środula",
    description: `Landing page for estate in Sosnowiec. The website consisted of
    product presentation, module with flat details available for download and a contact form.`,
    link: "https://ns-1.netlify.app",
    code: "https://github.com/mwatford/nowa_srodula",
    // more: "/nowa-srodula",
    img: "../../nowa-srodula.webp",
    tags: [TAGS.react, TAGS.gatsby],
  },
  {
    title: "Nowa Środula",
    description: `Second version of Nowa Środula website.`,
    link: "https://ns-2.netlify.app",
    code: "https://github.com/mwatford/nowa_srodula",
    // more: "/nowa-srodula",
    img: "../../ns2.webp",
    tags: [TAGS.react, TAGS.gatsby],
  },
  {
    title: "Segments Development",
    link: "https://segm.netlify.app",
    code: "https://github.com/mwatford/Segments",
    // more: "/segments-development",
    img: "../../segments.webp",
    tags: [TAGS.vue, TAGS.node],
  },
  {
    title: "Ostoja Bogacko",
    link: "https://ostbog.netlify.app",
    description: `Simple landing page for Ostoja Bogacko building land.`,
    code: "https://github.com/mwatford/ostoja",
    // more: "/ostoja-bogacko",
    img: "../../ostoja-bogacko.webp",
    tags: [TAGS.react, TAGS.gatsby],
  },
  {
    title: "Jusap",
    link: "http://jusap.netlify.app",
    // more: "/jusap",
    description: `Final landing page for jusap project.`,
    img: "../../jusap.webp",
    tags: [TAGS.react, TAGS.gatsby],
  },
  {
    title: "CSS Practice",
    link: "https://css-practice-mw.netlify.app",
    code: "https://github.com/mwatford/psd_to_html",
    description: `A place with projects focused on visual side of fontend.`,
    // more: "/css-practice",
    img: "../../css.webp",
    tags: [TAGS.vue, TAGS.css],
  },
  {
    title: "Game of life",
    link: "https://game-of-life-mw.netlify.app",
    description: `Implementation of John Conway's Game of Life, using Typescript`,
    code: "https://github.com/mwatford/game-of-life",
    // more: "/game-of-life",
    img: "../../game-of-life.webp",
    tags: [TAGS.typescript],
  },
  {
    title: "Recruitment Task 1",
    link: "https://rec-task-1.netlify.app/",
    code: "https://github.com/mwatford/huncwot-task",
    img: "../../huncwot.webp",
    tags: [TAGS.css, TAGS.html, TAGS.javascript, TAGS.gsap, TAGS.webpack],
    description: `The requirements consisted of implementing
    simple landing page based on the design, as well as animating and adding
    carousel element without any external libraries.`,
  },
  {
    title: "Recruitment Task 2",
    link: "https://rec-task-2.netlify.app",
    code: "https://github.com/mwatford/task-2",
    img: "../../ragnar.webp",
    tags: [TAGS.vue, TAGS.vuex, TAGS.vueRouter],
    description: `The requirement for this task were to create
    a guessing game, using vue.js and external API for rolling dice or picking a
    random card.`,
  },
  {
    title: "Recruitment Task 3",
    link: "https://rec-task-3.netlify.app",
    code: "https://github.com/mwatford/front-task-profil",
    img: "../../profil.webp",
    tags: [TAGS.javascript, TAGS.css, TAGS.html],
    description: `The requirements for this task consisted of
    creating a page for generating a random user using external API and displaying
    that information on the page, as well as storing information about 10 last
    generated users.`,
  },
  {
    title: "Recruitment Task 4",
    link: "https://rec-task-4.netlify.app",
    code: "https://github.com/mwatford/task",
    img: "../../ctr.webp",
    tags: [TAGS.react, TAGS.typescript],
    description: `Task objective was to create a modal for scheduling document export
    using react`,
  },
]
