import React from "react"

const Navbar = () => {
  return (
    <nav className="f-row navigation gutters">
      <img src="mw.png" alt="logo" className="navigation__logo" />
      <h1 className="navigation__title semi-bold">MELVILLE WATFORD</h1>
    </nav>
  )
}

export default Navbar
